import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      filtersCount: null,
      config: [],
      regions: [],
      countries: [],
      technicTypes: [],
      workTypes: [],
      technicModels: [],
      technicProducers: [],
      taskFiles: [],
      checklistTypes: [],
      statuses: [],
      checklistTemplates: [],
      criticalFlags: [
        { name: "Срочная", value: true, code: "critical" },
        { name: "Не срочная", value: false, code: "not_critical" },
      ],
      checkedFlags: [
        { name: "Проверено", value: true },
        { name: "Не проверено", value: false },
      ],
      ptcFlags: [
        { name: "ПТХ с решением", value: "PTC_CLOSED" },
        { name: "Не ПТХ", value: "NOT_PTC" },
        { name: "ПТХ в работе", value: "PTC" },
        { name: "ПТХ в ожидании", value: "WAIT_VERIFIED" },
        { name: "В работе у инженера", value: "WORK_ENGINEER" },
      ],
    };
  },
  mutations,
  actions,
  getters,
};
