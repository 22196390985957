<template>
  <!--В зависимости от роли нужно показывать разное количество строк в таблице-->
  <!--Чтобы корреккто восстанавливать table state подождем пока роль станет известна-->
  <template v-if="role">
    <MainTableComponent
      v-for="(table, index) in config.tables"
      :key="index"
      :row-count="tableRowCount"
      :filters-from-parent="filters"
      :state-key-name="`home-table-${index}-state`"
      :row-click-function="goToTask"
      sort-field="createdDateForFiltering"
      :filter-title="table.tableName"
      :statuses-config="table"
      :is-purchasing="table.isPurchasing"
    />
    <ScrollTopArrow />
  </template>
</template>

<script>
import StatusColor from "@/views/operator/components/StatusColor";
import MainTableComponent from "./TableComponents/MainTableComponent";
import ScrollTopArrow from "./TableComponents/ScrollTopArrow";
export default {
  name: "Home",
  components: { ScrollTopArrow, MainTableComponent, StatusColor },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    role() {
      return this.$store.getters.getRole;
    },
    config() {
      return this.$store.getters["data/config"];
    },
    tableRowCount() {
      return this.role === "OPERATOR" ? 10 : 20;
    },
  },
  async mounted() {
    window.addEventListener("scroll", this.saveTopScroll);
    setTimeout(this.setTopScroll, 500);
  },
  unmounted() {
    window.removeEventListener("scroll", this.saveTopScroll);
  },
  created() {
    this.filters = this.initFilters();
  },
  methods: {
    initFilters() {
      return {
        id: { value: null },
        companyName: { value: null },
        companyDistrict: { value: [] },
        techLabel: { value: null },
        header: { value: null },
        author: { value: null },
        createdDateForFiltering: {
          value: {
            range: {
              start: new Date("2023/03/05"),
              end: new Date(),
            },
          },
        },
        revisionRoles: { value: [] },
        checked: { value: [] },
        statusPtc: { value: [] },
        statusText: { value: [] },
        taskWorkType: { value: [] },
        critical: { value: [] },
        checklistType: { value: [] },
        mainEngineer: { value: [] },
      };
    },
    goToTask(event) {
      this.$router.push(`/task/${event.data.id}`);
    },
  },
};
</script>
