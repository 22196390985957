import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import store from "@/store/index.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true, title: "Агротрак. Сервис" },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
    meta: { requiresUnauth: true, title: "Вход" },
  },
  {
    path: "/restore",
    name: "RestorePassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "restorepassword" */ "../views/auth/RestorePassword.vue"
      ),
    meta: { requiresUnauth: true, title: "Забыл пароль" },
  },
  {
    path: "/users",
    name: "UsersList",
    component: () =>
      import(
        /* webpackChunkName: "userlist" */ "../views/myprofiles/UsersList.vue"
      ),
    meta: { requiresAuth: true, title: "Сотрудники" },
  },
  {
    path: "/create_user",
    name: "UserCreate",
    component: () =>
      import(
        /* webpackChunkName: "usercreate" */ "../views/myprofiles/UserCreate.vue"
      ),
    meta: { requiresAuth: true, title: "Создать профиль" },
  },
  {
    path: "/create_task",
    name: "CreateTask",
    component: () =>
      import(
        /* webpackChunkName: "taskcreate" */ "../views/operator/TaskCreate.vue"
      ),
    meta: { requiresAuth: true, title: "Создать заявку" },
  },
  {
    path: "/clients",
    name: "ClientList",
    component: () =>
      import(
        /* webpackChunkName: "clientlist" */ "../views/operator/ClientList.vue"
      ),
    meta: { requiresAuth: true, title: "Клиенты" },
  },
  {
    path: "/create_client",
    name: "CreateClient",
    component: () =>
      import(
        /* webpackChunkName: "clientcreate" */ "../views/operator/ClientCreate.vue"
      ),
    meta: { requiresAuth: true, title: "Создать клиента" },
  },
  {
    path: "/client/:id",
    name: "ClientDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "clientdetail" */ "../views/operator/ClientDetail.vue"
      ),
    meta: { requiresAuth: true, title: "Редактирование клиента" },
  },
  {
    path: "/user/:id",
    name: "UserDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "userdetail" */ "../views/operator/UserDetail.vue"
      ),
    meta: { requiresAuth: true, title: "Редактирование сотрудника" },
  },
  {
    path: "/tasks",
    name: "TaskList",
    component: () =>
      import(
        /* webpackChunkName: "tasklist" */ "../views/operator/TaskList.vue"
      ),
    meta: { requiresAuth: true, title: "Заявки" },
  },
  {
    path: "/create_checklist",
    name: "CreateChecklist",
    component: () => import("../views/ChecklistMaker/CreateChecklist"),
    meta: { requiresAuth: true, title: "Конструктор чеклистов" },
  },
  {
    path: "/edit_checklist/:id",
    name: "EditChecklist",
    component: () => import("../views/ChecklistMaker/ChecklistEdit"),
    meta: { requiresAuth: true, title: "Редактировать чеклист" },
  },
  {
    path: "/checklist_maker",
    name: "ChecklistMaker",
    component: () => import("../views/ChecklistMaker/ChecklistMaker"),
    meta: { requiresAuth: true, title: "Конструктор техники" },
  },
  {
    path: "/technic_maker",
    name: "TechnicMaker",
    component: () => import("../views/TechnicMaker/TechnicMaker"),
    meta: { requiresAuth: true, title: "Конструктор техники" },
  },
  {
    path: "/technic_maker/:uid",
    name: "StandardHours",
    props: true,
    component: () => import("../views/TechnicMaker/StandardHours"),
    meta: { requiresAuth: true, title: "Конструктор нормо-часов" },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "../views/myprofiles/Profile.vue"
      ),
    meta: { requiresAuth: true, title: "Профиль" },
  },
  {
    path: "/task/:id",
    name: "TaskDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "taskdetail" */ "../views/operator/TaskDetail.vue"
      ),
    meta: { requiresAuth: true, title: "Заявка" },
  },
  {
    path: "/reports",
    name: "MainReportPage",
    component: () => import("../views/statistic/reports/MainReportPage"),
    meta: { requiresAuth: true, title: "Отчеты" },
  },
  {
    path: "/engineerReport",
    name: "engineerReport",
    component: () =>
      import("../views/statistic/reports/SelectedEngineerReport"),
    props: (route) => ({
      id: route.query.engineerId,
      dateStart: route.query.dateStart,
      dateEnd: route.query.dateEnd,
      workTypes: route.query.workTypes,
    }),
    meta: { requiresAuth: true, title: "Отчет по инженеру" },
  },
  {
    path: "/unusedDetails",
    name: "UnusedDetails",
    component: () =>
      import("../views/statistic/reports/UnusedDetails"),
    props: (route) => ({
      id: route.query.engineerId,
      dateStart: route.query.dateStart,
      dateEnd: route.query.dateEnd,
    }),
    meta: { requiresAuth: true, title: "Отчёт по запчастям" },
  },
  {
    path: "/aboutMe",
    name: "reportAboutMe",
    component: () => import("../views/statistic/reports/AboutMe"),
    props: (route) => ({
      id: route.query.engineerId,
      dateStart: route.query.dateStart,
      dateEnd: route.query.dateEnd,
    }),
    meta: { requiresAuth: true, title: "Мой отчет" },
  },
  {
    path: "/attendance",
    name: "attendanceLog",
    component: () => import("@/views/statistic/AttendanceLog"),
    meta: {
      requiresAuth: true,
      title: "Журнал посещаемости",
    },
  },
  { path: "/404", component: NotFound },
  { path: "/:notFound(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  // Vue.nextTick(() => {
  document.title = to.meta.title || "AGRO";
  // });
});

router.beforeEach(function (to, _, next) {
  let token = localStorage.getItem("access_token");
  // console.log("router token here", token);
  if (
    to.meta.requiresAuth &&
    !store.getters.isAuthenticated &&
    (store.getters.doneAuthTokens || !token)
  ) {
    // console.log("Bad, go to login");
    // save to store to restore after login
    if (to.path !== "/login") {
      localStorage.setItem("login_redirect", to.path);
    }
    next("/login");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
