<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <div class="p-mb-3 p-d-flex p-align-center">
          <Button
            :icon="showTable ? 'pi pi-minus' : 'pi pi-plus'"
            class="p-ml-3"
            @click="hideTableHandler"
          />
          <span v-if="!showTable" class="p-text-bold p-ml-3">
            {{ tableName }} ({{ totalRecords }} шт.)
          </span>
        </div>
        <DataTable
          v-if="showTable"
          ref="table"
          :value="tasks"
          :paginator="true"
          class="p-datatable-customers p-datatable-striped"
          :rows="rowCount"
          dataKey="id"
          :rowHover="true"
          :autoLayout="true"
          :loading="isLoading"
          v-model:filters="filters"
          v-model:selection="selectedTask"
          selectionMode="single"
          filterDisplay="row"
          stateStorage="session"
          :stateKey="stateKeyName"
          responsiveLayout="scroll"
          :rowClass="rowClass"
          @row-click="rowClickFunction"
          :lazy="true"
          :totalRecords="totalRecords"
          :sortOrder="sortOrder"
          :sotrField="sortField"
          @page="onPage($event)"
          @sort="onSort($event)"
          @filter="onFilter($event)"
        >
          <template #header>
            <div class="p-d-flex p-jc-between">
              <div class="table-header p-ai-center p-d-flex">
                <Button
                  v-if="statusesConfig.id && permissions['customFilters']"
                  @click="openDeleteLikeTableConfirmation"
                  class="p-mr-2 p-button-danger"
                  icon="pi pi-times"
                  title="Удалить фильтр"
                />
                <Button
                  v-if="permissions['customFilters']"
                  @click="openLikeTableDialog"
                  icon="pi pi-heart"
                  title="Сохранить фильтр"
                />

                <span v-if="role === ROLE.WARRANTY_ENGINEER" class="p-ml-3">
                  <Checkbox
                    :disabled="'forMe' in statusesConfig"
                    v-model="tasksForWarrantyEngineer"
                    :binary="true"
                  />
                  <label class="p-ml-1">Мои заявки</label>
                </span>

                <div class="p-ml-3 p-mt-1">
                  {{ tableName }} ({{ totalRecords }} шт.)
                </div>
              </div>
              <div>
                <Button
                  class="p-button-secondary p-mr-3"
                  @click="clearAndSetFilters"
                >
                  Очистить
                </Button>
                <Button label="Отфильтровать" @click="refresh" />
                <Button
                  v-if="
                    permissions['load_tasks_from_my_filter'] &&
                    statusesConfig.myFilter === 'true'
                  "
                  class="p-ml-3"
                  label="Скачать заявки"
                  @click="downloadTasksList"
                />
              </div>
            </div>
          </template>
          <template #empty>Заявки не найдены</template>
          <template #loading>Загружаем заявки...</template>
          <Column field="unreadMessagesCount" :sortable="true">
            <template #header>
              <i class="pi pi-envelope" />
            </template>
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                <Badge
                  v-if="slotProps.data.unreadMessagesCount"
                  severity="success"
                >
                  {{ slotProps.data.unreadMessagesCount }}
                </Badge>
              </div>
            </template>
          </Column>
          <Column field="critical" :showFilterMenu="false">
            <template #header>
              <img
                src="/assets/table_critical.svg"
                style="width: 24px; height: 24px"
              />
            </template>
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                <img
                  v-if="slotProps.data.critical"
                  src="/assets/critical.svg"
                  style="width: 24px; height: 24px"
                  title="Cрочная заявка"
                />
                <img
                  v-else
                  style="width: 24px; height: 24px"
                  src="/assets/not_critical.svg"
                  title="Не срочная заявка"
                />
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="criticalFlags"
                optionLabel="name"
                optionValue="value"
                style="width: 30px; height: 30px"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'criticals' in statusesConfig"
              >
                <template #value="slotProps">
                  <img
                    v-for="option of slotProps.value"
                    :key="option"
                    :src="
                      option === true
                        ? '/assets/critical.svg'
                        : '/assets/not_critical.svg'
                    "
                    style="width: 16px; height: 16px"
                  />
                </template>
                <template #option="slotProps">
                  <img
                    :src="'/assets/' + slotProps.option.code + '.svg'"
                    style="width: 24px; height: 24px"
                    :title="slotProps.option.name"
                  />
                </template>
              </MultiSelect>
            </template>
          </Column>
          <Column field="checked" :showFilterMenu="false">
            <template #header>
              <i style="color: #4caf50" class="pi pi-check" />
            </template>
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                <i v-if="slotProps.data.checked === true" class="pi pi-check" />
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="checkedFlags"
                optionLabel="name"
                optionValue="value"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'checkeds' in statusesConfig"
                style="width: 30px; height: 30px"
              />
            </template>
          </Column>
          <Column field="statusPtc" header="ПТХ" :showFilterMenu="false">
            <template #body="slotProps">
              <div class="p-d-flex p-ai-center">
                <!--                TODO Попробовать сократить статус у ПТХ-->
                <img
                  v-if="
                    (slotProps.data.statusPtc === 'PTC' ||
                      slotProps.data.statusPtc === 'WORK_ENGINEER') &&
                    permissions['see_hammer']
                  "
                  src="/assets/hammer_yellow.svg"
                  style="width: 20px; height: 20px"
                  class="p-ml-1 p-mr-2 icons"
                />
                <img
                  v-if="
                    slotProps.data.statusPtc === 'WAIT_VERIFIED' &&
                    permissions['see_hammer']
                  "
                  src="/assets/hammer_red.svg"
                  style="width: 20px; height: 20px"
                  class="p-ml-1 p-mr-2 icons"
                />
                <img
                  v-if="
                    (slotProps.data.statusPtc === 'PTC_CLOSED' ||
                      slotProps.data.statusPtc === 'NOT_PTC') &&
                    permissions['see_hammer']
                  "
                  src="/assets/hammer_green.svg"
                  style="width: 20px; height: 20px"
                  class="p-ml-1 p-mr-2 icons"
                />
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="ptcFlags"
                optionLabel="name"
                optionValue="value"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'ptcStatuses' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="id"
            header="Номер"
            :showFilterMenu="false"
            :sortable="true"
          >
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'TaskDetail',
                  params: { id: slotProps.data.id },
                }"
                >{{ slotProps.data.number }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                @keypress.enter="loadFilteredTasks"
                class="p-column-filter filter-1"
                :placeholder="``"
                v-tooltip.top.focus="''"
                :disabled="'number' in statusesConfig"
              />
            </template>
          </Column>
          <Column field="statusText" header="Статус" :showFilterMenu="false">
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'TaskDetail',
                  params: { id: slotProps.data.id },
                }"
              >
                <status-color
                  :status="slotProps.data.status"
                  :client-task="slotProps.data.authorIsEmployeeOfClient"
                />
                {{ slotProps.data.statusText.name }}
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="statuses"
                optionLabel="name"
                optionValue="value"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="
                  'statuses' in statusesConfig &&
                  statusesConfig.myFilter === 'false'
                "
              />
            </template>
          </Column>
          <Column
            field="companyName"
            header="Клиент"
            sortField="companyName"
            filterField="companyName"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.companyName }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                @keypress.enter="loadFilteredTasks"
                class="p-column-filter filter-1"
                :disabled="'companyName' in statusesConfig"
              />
            </template>
          </Column>
          <Column field="techLabel" header="Техника" :showFilterMenu="false">
            <template #body="slotProps">
              {{ slotProps.data.techLabel }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                @keypress.enter="loadFilteredTasks"
                class="p-column-filter filter-1"
                :disabled="'technicName' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="taskWorkType"
            header="Вид ремонта"
            sortField="taskWorkType"
            filterField="taskWorkType"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.taskWorkType.name }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="workTypeList"
                optionLabel="name"
                optionValue="value"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'workTypes' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="checklistType"
            header="Чеклист"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.checklistType.label }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="checklist_templates"
                optionLabel="label"
                optionValue="label"
                :filter="true"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'checklistNames' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="mainEngineer"
            header="Ответственный инженер"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data?.mainEngineer?.label }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback"
                class="p-column-filter multiselect"
                :options="engineers"
                optionLabel="label"
                optionValue="label"
                :filter="true"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="'engineerNames' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="companyDistrict"
            header="Район"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.companyDistrict }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <MultiSelect
                v-model="filterModel.value"
                @change="filterCallback()"
                class="p-column-filter multiselect"
                :options="REGIONS_LIST"
                option-group-label="label"
                option-group-children="items"
                option-label="name"
                option-value="name"
                empty-filter-message="Совпадений не найдено"
                filter
                :disabled="'companyDistrict' in statusesConfig"
              />
            </template>
          </Column>
          <Column field="author" header="Автор" :showFilterMenu="false">
            <template #body="slotProps">
              {{ slotProps.data.author }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                @keypress.enter="loadFilteredTasks"
                class="p-column-filter filter-1"
                :disabled="'authorName' in statusesConfig"
              />
            </template>
          </Column>
          <Column
            field="createdDateForFiltering"
            header="Дата оформления"
            :showFilterMenu="false"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ slotProps.data.createdDate }}
            </template>
            <template #filter="{ filterModel }">
              <RangeDatePicker v-model:range="filterModel.value.range" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <LikeDialog @createNewFilter="setTableState" ref="likeDialog" />
  <ConfirmDialog group="deleteFilterDialog"></ConfirmDialog>
</template>

<script>
import StatusColor from "@/views/operator/components/StatusColor";
import { Calendar, DatePicker } from "v-calendar";
import { environment } from "@/config";
import dayjs from "dayjs";
import RangeDatePicker from "../operator/components/RangeDatePicker";
import LikeDialog from "@/views/TableComponents/LikeDialog";
import { mapMutations } from "vuex";
import { REGIONS_LIST } from "@/models/regionsList";
import { ROLE } from "@/models/roles";

export default {
  components: {
    LikeDialog,
    RangeDatePicker,
    StatusColor,
    Calendar,
    DatePicker,
  },
  props: [
    "statusesConfig",
    "filtersFromParent",
    "stateKeyName",
    "rowClickFunction",
    "sortField",
    "filterTitle",
    "rowCount",
    "showSaveFiltersButton",
    "isLinkPurchasingPartsTasks",
    "isPurchasing",
  ],
  name: "MainTableComponent",
  data() {
    return {
      maxFilterCount: 10,
      desc: null,
      fieldForSorting: "created",
      tableName: "",
      tasksForWarrantyEngineer: false,
      isLoading: false,
      tasks: [],
      selectedTask: null,
      filters: this.filtersFromParent,
      totalRecords: 0,
      showTable: true,
      sortOrder: -1,

      REGIONS_LIST,
      ROLE,
    };
  },

  watch: {
    statusesConfig: function (newValue) {
      if (!newValue.search && !newValue.technic) {
        this.clearAndSetFilters();
      }
    },
    userData: function () {
      this.loadFilteredTasks();
    },
  },

  computed: {
    userData() {
      return this.$store.getters["userData"];
    },
    filtersCount() {
      return this.$store.getters["data/filtersCount"];
    },
    myId() {
      return this.$store.getters.getId;
    },
    role() {
      return this.$store.getters.getRole;
    },
    selectedUserRegions() {
      return this.$store.getters.selectedUserRegions;
    },
    selectedTechnicProducers() {
      return this.$store.getters.selectedTechnicProducers;
    },
    ptcFlags() {
      return this.$store.getters["data/ptcFlags"];
    },

    checkedFlags() {
      return this.$store.getters["data/checkedFlags"];
    },

    criticalFlags() {
      return this.$store.getters["data/criticalFlags"];
    },

    checklist_templates() {
      return this.$store.getters["data/checklistTemplates"].map((t) => {
        return { label: t.name };
      });
    },

    statuses() {
      return this.$store.getters["data/statuses"];
    },

    workTypeList() {
      return this.$store.getters["data/workTypes"];
    },

    engineers() {
      return this.$store.getters["myprofiles/engineers"].map((e) => {
        return {
          label: `${e.lastname} ${e.firstname}`,
        };
      });
    },

    permissions() {
      return this.$store.getters.getPermissions;
    },
  },
  async mounted() {
    window.addEventListener("scroll", this.saveTopScroll);
    let currentTableState = JSON.parse(
      sessionStorage.getItem(`${this.stateKeyName}`)
    );

    this.showTable = JSON.parse(
      sessionStorage.getItem(`${this.stateKeyName}-show`) ??
        this.stateKeyName === "task-list-state"
    );

    this.lazyParams = {
      first: 0,
      rows: currentTableState?.rows,
      filters: this.filters,
      page: 0,
    };

    if (currentTableState) {
      this.lazyParams.first = currentTableState.first;
      this.lazyParams.page = this.lazyParams.first / this.lazyParams.rows;
      this.sortOrder = currentTableState.sortOrder ?? this.sortOrder;
      this.fieldForSorting = this.getFieldForSorting(
        currentTableState.sortField
      );
    }

    if (!this.statusesConfig.search && !this.statusesConfig.technic) {
      if (this.statusesConfig.myFilter === "true") {
        await this.loadFilteredTasks();
      } else {
        this.setFiltersFromBackend();
      }
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.saveTopScroll);
  },
  methods: {
    hideTableHandler() {
      this.showTable = !this.showTable;
      sessionStorage.setItem(`${this.stateKeyName}-show`, this.showTable);
    },
    rowClass(data) {
      const reviseRoles = [
        ROLE.MAIN_OPERATOR,
        ROLE.OPERATOR,
        ROLE.HEAD_OF_SERVICE,
        ROLE.WARRANTY_ENGINEER,
        ROLE.CHIEF_ENGINEER,
        ROLE.ENGINEER,
      ];
      if (data.revisionRole && reviseRoles.includes(this.role)) {
        return data.revisionRole === ROLE.ENGINEER
          ? "engineer-revise"
          : "warranty-revise";
      }
      if (
        this.isPurchasing === true &&
        data.purchasingResponsible === this.role
      ) {
        return "purchasing-row";
      }

      if (data.debtor === true && data.verified === false) {
        return "debtor-row";
      }

      return null;
    },
    ...mapMutations({
      setConfig: "data/setConfig",
    }),
    openDeleteLikeTableConfirmation() {
      this.$confirm.require({
        message: `Вы действительно хотите удалить фильтр "${this.statusesConfig.name}"?`,
        group: "deleteFilterDialog",
        header: "Удаление фильтра",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteLikedTable();
        },
      });
    },
    async deleteLikedTable() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/filters/${this.statusesConfig.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "DELETE",
          }
        );
        await this.$router.push("/");
        this.setConfig(await response.json());
        this.$toast.add({
          severity: "success",
          summary: "Фильтр удален",
          detail: `Фильтр "${this.statusesConfig.name}" успешно удален`,
          life: 6000,
        });
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async setTableState() {
      this.isLoading = true;

      try {
        const payload = {
          name: this.$refs.likeDialog.buttonName,
          description:
            this.$refs.likeDialog.tableName !== ""
              ? this.$refs.likeDialog.tableName
              : this.$refs.likeDialog.buttonName,
          criticals: this.filters.critical.value,
          checkeds: this.filters.checked.value,
          ptcStatuses: this.filters.statusPtc.value,
          number: this.filters.id.value,
          statuses: this.filters.statusText.value,
          companyName: this.filters.companyName.value,
          technicName: this.filters.techLabel.value,
          workTypes: this.filters.taskWorkType.value,
          checklistNames: this.filters.checklistType.value,
          engineerNames: this.filters.mainEngineer.value,
          companyDistrict: this.filters.companyDistrict.value,
          authorName: this.filters.author.value,
          forMe: this.tasksForWarrantyEngineer,
          revisionRoles: this.filters.revisionRoles.value,
        };

        let response = await fetch(`${environment.apiUrl}/filters`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        });

        this.setConfig(await response.json());

        this.$toast.add({
          severity: "success",
          summary: "Фильтр создан",
          detail: `Свой фильтр "${payload.name}" создан`,
          life: 6000,
        });
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Не удалось сохранить фильтр",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    openLikeTableDialog() {
      if (this.filtersCount > this.maxFilterCount) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании собственного фильтра",
          detail: `Вы не можете создавать больше ${this.maxFilterCount} фильтров`,
          life: 6000,
        });
        return;
      }
      this.$refs.likeDialog.displayDialog = true;
    },
    setTopScroll() {
      const scroll = sessionStorage.getItem(`${this.stateKeyName}-scroll`);
      window.scrollTo(0, parseInt(scroll));
    },
    saveTopScroll() {
      const scrollDistance =
        window.pageYOffset || document.documentElement.scrollTop;
      sessionStorage.setItem(
        `${this.stateKeyName}-scroll`,
        `${scrollDistance}`
      );
    },
    async searchByNumber(number) {
      this.isLoading = true;
      this.clearFilters();
      this.tableName = "Поиск по номеру заявки";
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/number?number=${number}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.tasks = this.createTableDataModel(await response.json());
      } catch (err) {
        const error = err.message || "Не получилось загрузить список заявок";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка заявок!",
          detail: error,
          life: 6000,
        });
        console.log(err);
      }

      this.isLoading = false;
    },

    setFilterStringHandler(configName, filterName) {
      let dt = this.$refs.table;

      if (this.statusesConfig[configName]) {
        dt.filters[filterName].value = this.statusesConfig[configName];
      }
    },

    setFilterBooleanHandler(configName, filterName) {
      let dt = this.$refs.table;

      if (this.statusesConfig[configName]) {
        dt.filters[filterName].value = [];
        for (const status of [].concat(this.statusesConfig[configName])) {
          dt.filters[filterName].value.push(status === "true");
        }
      }
    },

    setFiltersArrayHandler(configName, filterName) {
      let dt = this.$refs.table;

      if (this.statusesConfig[configName]) {
        dt.filters[filterName].value = [];
        for (const status of [].concat(this.statusesConfig[configName])) {
          dt.filters[filterName].value.push(status);
        }
      }
    },

    setFiltersFromBackend() {
      let dt = this.$refs.table;

      this.tableName = this.filterTitle ?? this.statusesConfig.description;

      this.setFiltersArrayHandler("statuses", "statusText");
      this.setFiltersArrayHandler("workTypes", "taskWorkType");
      this.setFiltersArrayHandler("ptcStatuses", "statusPtc");
      this.setFiltersArrayHandler("checklistNames", "checklistType");
      this.setFiltersArrayHandler("engineerNames", "mainEngineer");
      this.setFiltersArrayHandler("revisionRoles", "revisionRoles");

      this.setFilterBooleanHandler("criticals", "critical");
      this.setFilterBooleanHandler("checkeds", "checked");

      this.setFilterStringHandler("number", "id");
      this.setFilterStringHandler("companyName", "companyName");
      this.setFilterStringHandler("technicName", "techLabel");
      this.setFilterStringHandler("authorName", "author");
      this.setFilterStringHandler("companyDistrict", "companyDistrict");

      this.tasksForWarrantyEngineer = this.statusesConfig?.forMe === "true";

      dt.saveState();
      dt.restoreState();

      this.loadFilteredTasks();
    },

    refresh() {
      this.$refs.table.d_first = 0;
      this.lazyParams.page = 0;
      this.loadFilteredTasks();
    },

    async getFiltersAsRequestBody() {
      let technicId = null;
      if (this.$route.query.technic) {
        technicId = Number(this.$route.query.technic);
      }

      let regionsFilter = [];
      regionsFilter = this.selectedUserRegions.map((region) => {
        return region.uid;
      });

      let techProducersFilter = [];
      techProducersFilter = this.selectedTechnicProducers.map((producer) => {
        return producer.uid;
      });

      //TODO Поправить логику, я не понимаю
      let employeeCompanyName = null;
      if (
        localStorage.getItem("employeeCurrentCompany") !== "undefined" &&
        localStorage.getItem("employeeCurrentCompany") !== null &&
        localStorage.getItem("employeeCurrentCompany") !== "null"
      ) {
        employeeCompanyName = JSON.parse(
          localStorage.getItem("employeeCurrentCompany")
        ).uid;
      } else {
        if (this.role === ROLE.EMPLOYEE_OF_CLIENT) {
          employeeCompanyName = await this.$store.getters["userData"]
            .companies[0].uid;
        }
      }

      this.filters.createdDateForFiltering.value.range.start.setHours(
        0,
        0,
        0,
        0
      );
      this.filters.createdDateForFiltering.value.range.end.setHours(
        23,
        59,
        59,
        0
      );

      let forMeFilter = null;
      if (this.tasksForWarrantyEngineer === true) {
        forMeFilter = this.myId;
      }

      const currentPage = this.lazyParams.page;

      this.desc = this.sortOrder === -1;

      return JSON.stringify({
        page: currentPage,
        size: this.rowCount,

        sortBy: this.fieldForSorting,
        desc: this.desc,

        statuses: this.filters.statusText.value,
        number: this.filters.id.value,
        technicName: this.filters.techLabel.value,
        authorName: this.filters.author.value,
        workTypes: this.filters.taskWorkType.value,
        ptcStatuses: this.filters.statusPtc.value,
        checkeds: this.filters.checked.value,
        criticals: this.filters.critical.value,
        companyName: this.filters.companyName.value,
        companyDistrict: this.filters.companyDistrict.value,
        checklistNames: this.filters.checklistType.value,
        engineerNames: this.filters.mainEngineer.value,
        dateStart: this.filters.createdDateForFiltering.value.range.start.toISOString(),
        dateEnd: this.filters.createdDateForFiltering.value.range.end.toISOString(),
        revisionRoles: this.filters.revisionRoles.value,
        regionUids: regionsFilter,
        companyUid: employeeCompanyName,
        technicProducerIds: techProducersFilter,
        warrantyEngineerId: forMeFilter,
        technicId: technicId,
      });
    },

    async loadFilteredTasks() {
      if (!this.$store.getters.token || this.userData.role === undefined) {
        return;
      }

      this.isLoading = true;

      try {
        const payload = await this.getFiltersAsRequestBody();
        const response = await fetch(
          `${environment.apiUrl}/v2/tasks/filter_sort`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: payload,
          }
        );
        this.tasks = await response.json();
        this.totalRecords = this.tasks.count;
        this.tasks = this.tasks.items;

        this.tasks = this.createTableDataModel(this.tasks);
      } catch (err) {
        const error = err.message || "Не получилось загрузить список заявок";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка заявок!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
      setTimeout(this.setTopScroll, 600);
    },

    async downloadTasksList() {
      this.isLoading = true;

      try {
        const payload = await this.getFiltersAsRequestBody();
        const response = await fetch(
          `${environment.apiUrl}/v2/tasks/filter_sort/excel`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            body: payload,
          }
        );

        //TODO Вынести весь такой функционал в плагин
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Список.xls");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.error(err);
      }

      this.isLoading = false;
    },

    createTableDataModel(data) {
      if (!data) return [];
      return data.map((t) => {
        const d = new Date(t.created);
        let companyName;

        if (this.permissions["see_nonofficial_name"]) {
          companyName = t.companyName;
        } else {
          companyName = t.companyOfficialName;
        }
        return {
          ...t,
          statusText: this.getTaskStatus(t?.status),
          companyName: companyName,
          taskWorkType: this.getNameByType(t?.workType),
          checklistType: { label: t?.checklistName },
          companyDistrict: t.companyDistrict,
          mainEngineer: { label: t?.engineerName },
          author: t.authorName,
          createdDate: dayjs(d).format("DD.MM.YY HH:mm"),
          createdDateForFiltering: dayjs(d).format("YY.MM.DD HH:mm"),
          techLabel: t.technicName,
        };
      });
    },

    getTaskStatus(statusValue) {
      return this.statuses.filter((s) => {
        return s.value === statusValue;
      })[0];
    },

    getNameByType(workType) {
      if (!this.workTypeList) return;
      return this.workTypeList.filter((wt) => {
        return wt.value === workType;
      })[0];
    },

    onPage(event) {
      this.lazyParams = event;
      this.loadFilteredTasks();
    },

    getFieldForSorting(sortField) {
      if (sortField !== "unreadMessagesCount") {
        return "created";
      }
      return "unreadMessagesCount";
    },

    onSort(event) {
      this.sortOrder *= -1;

      this.fieldForSorting = this.getFieldForSorting(event.sortField);

      this.lazyParams.page = 0;

      this.loadFilteredTasks();
    },

    onFilter() {
      this.lazyParams.filters = this.filters;
    },

    clearAndSetFilters() {
      this.clearFilters();
      this.setFiltersFromBackend();
      window.dispatchEvent(new CustomEvent("clearTopBarFilter"));
    },

    clearFilters() {
      let dt = this.$refs.table;
      for (const key in dt.filters) {
        dt.filters[key].value = null;
      }

      dt.filters["createdDateForFiltering"].value = {
        range: {
          start: new Date("2023/03/05"),
          end: new Date(),
        },
      };

      dt.filters["checked"].value = [];
      dt.filters["statusPtc"].value = [];
      dt.filters["statusText"].value = [];
      dt.filters["taskWorkType"].value = [];
      dt.filters["critical"].value = [];
      dt.filters["checklistType"].value = [];
      dt.filters["mainEngineer"].value = [];

      dt.d_first = 0;

      this.lazyParams.page = 0;

      dt.saveState();
      dt.restoreState();
    },
  },
};
</script>

<style scoped>
.multiselect {
  width: 100px;
}
</style>

<style>
/*TODO как то вылезти из общего стайла */
.p-multiselect-trigger {
  width: 2rem !important;
}
.engineer-revise {
  background-color: rgba(18, 102, 241, 0.25) !important;
}
.engineer-revise:hover {
  background-color: rgba(18, 102, 241, 0.3) !important;
}
.warranty-revise {
  background-color: rgba(219, 152, 35, 0.25) !important;
}
.warranty-revise:hover {
  background-color: rgba(219, 152, 35, 0.3) !important;
}
.debtor-row {
  background-color: #ffe6e6 !important;
}
.debtor-row:hover {
  background-color: #ffcccc !important;
}

.purchasing-row {
  background-color: #ffffaa !important;
}

.purchasing-row:hover {
  background-color: #ffff66 !important;
}
</style>
